import { Card, CloseButton, Form, Offcanvas, Stack } from "react-bootstrap";
import useOffcanvasStore from "../../store/useOffcanvasStore";
import useMapStore from "../../store/useMapStore";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";

function LayerSelection() {
  let { hideHeader } = useParams();

  const layerShown = useOffcanvasStore((state) => state.layerSelectionShown);
  const setLayerShown = useOffcanvasStore(
    (state) => state.setLayerSelectionShown
  );
  const allLayerViews = useMapStore((state) => state.layers);

  const [render, setRender] = useState(true);
  const [layers, setLayers] = useState([]);

  useEffect(() => {
    if (allLayerViews) {
      setLayers(
        allLayerViews.filter((e: any) => {
          return (
            e.layer.title !== "SVM Internal" &&
            e.layer.title !== null &&
            e.layer.title !== "World Imagery"
          );
        })
      );
    }
  }, [allLayerViews]);

  const checkLayerHandler = (key: number) => {
    allLayerViews.forEach((e: any) => {
      if (e.layer.id === key) {
        e.visible = !e.visible;
        setRender(!render);
      }
    });
  };

  const searchHandler = (e: React.FormEvent<HTMLInputElement>) => {
    setLayers(
      allLayerViews.filter(
        (obj: any) =>
          obj.layer.title
            ?.toLocaleLowerCase()
            .includes(e.currentTarget.value) && obj.layer.type !== "group"
      )
    );
  };

  const OffcanvasStyle: React.CSSProperties = {
    height: hideHeader === "hideheader" ? "100vh" : "90vh",
    bottom: 0,
    top: "auto",
  };

  return (
    <div className="layersSelection">
      <Offcanvas
        style={OffcanvasStyle}
        backdropClassName="opacity-0"
        show={layerShown}
        onHide={() => {
          setLayerShown(false);
        }}
        backdrop={false}
        scroll={true}
        placement={"end"}
      >
        <Offcanvas.Header className="w-100" style={OffcanvasHeaderStyle}>
          <CloseButton
            className="me-auto"
            onClick={() => {
              setLayerShown(false);
            }}
          />

          <div className="d-inline-flex align-items-center mainColor">
            <h4 className="my-3 me-3 text-center fw-bold mainColor">الطبقات</h4>
            <FontAwesomeIcon size="2x" icon={faLayerGroup} />
          </div>
        </Offcanvas.Header>

        <Offcanvas.Body className="text-end">
          <Stack gap={3}>
            <div className="d-flex justify-content-center">
              <div className="form-group w-100 mt-3">
                <input
                  onChange={searchHandler}
                  type="text"
                  className="form-control text-center"
                  id="usr"
                  required
                />
                <label>بحث</label>
              </div>
            </div>

            {layers?.map((e: any) => {
              return (
                <Card style={cardStyle} className="py-1" key={e.layer.id}>
                  <div className="row w-100">
                    <div className="col-1 text-end ">
                      <Form.Check
                        style={toggleStyle}
                        className="custom-check"
                        type="switch"
                        checked={e.visible}
                        onChange={(evt) => checkLayerHandler(e.layer.id)}
                      />
                    </div>
                    <div className="col text-center thirdColor">
                      <h5>{e.layer.title}</h5>
                    </div>
                  </div>
                </Card>
              );
            })}
          </Stack>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

//

export default LayerSelection;

const OffcanvasHeaderStyle: React.CSSProperties = {
  background: "#efefefef",
  borderRadius: " 0px 0px 15px 15px",
};

const toggleStyle: React.CSSProperties = {
  transform: "scale(1.5)",
  alignSelf: "center",
  color: "#fff",
  backgroundColor: "#fff",
};

const cardStyle: React.CSSProperties = {
  alignItems: "center",
  border: "none",
  boxShadow: "none",
};

import { Navbar, Container, Nav, Modal, Button } from 'react-bootstrap';
import HeaderButtons from "./headerButtons";
import { useState, useEffect } from 'react';

function HeaderNavBar() {

  const [showModal, setShowModal] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);

  const handleModalShow = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>


      <Navbar expand="lg">
        <Container fluid>
          <Navbar.Brand>
            <img
              src="/img/logo-wb.png"
              alt="Left Logo"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>

          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            onClick={() => handleModalShow()}
            className="custom-toggler"
          >
            <span className="custom-toggler-icon">
              <img
                src="/img/menuBarsIcon.png"
                alt="Toggle Icon"
                width="30"
                height="30"
              />
            </span>
          </Navbar.Toggle>

          {(!isSmallScreen || showModal) && (
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">

                <HeaderButtons />

                {/* Add more Nav.Link components as needed */}
              </Nav>
            </Navbar.Collapse>
          )}


          {/* <div className="customLinks">
          <HeaderButtons />
        </div> */}

        </Container>

      </Navbar>


      {isSmallScreen && (
        <Modal show={showModal} onHide={handleModalClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>
              Map Explorer Tools
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Nav className="flex-column">

              <HeaderButtons />


            </Nav>
          </Modal.Body>
          {/* You can add a Modal.Footer if needed */}
        </Modal>
      )}
    </>
  );
};
export default HeaderNavBar;

import NavBarButton from "../../buttons/navBarButton";
import sketchImage from "../../../img/itbaToolIconDraw.png";
import weatherImage from "../../../img/itbaToolIconTemp.png";
import menuBars from "../../../img/menuBarsIcon.png";
import itbaToolIcon1 from "../../../img/itbaToolIcon1.png";
import itbaToolIcon2 from "../../../img/itbaToolIcon2.png";
import itbaToolIcon3 from "../../../img/itbaToolIcon3.png";
import itbaToolIcon4 from "../../../img/itbaToolIcon4.png";

function HeaderButtons() {
  return (
    <>
      <div className="navBarBtns" hidden>
        <NavBarButton icon={itbaToolIcon1} />
      </div>

      <div className="navBarBtns">
        <NavBarButton icon={sketchImage} action={"sketch"} />
      </div>

      <div className="navBarBtns" hidden>
        <NavBarButton icon={itbaToolIcon2} />
      </div>

      <div className="navBarBtns" hidden>
        <NavBarButton icon={itbaToolIcon3} />
      </div>

      <div className="navBarBtns">
        <NavBarButton icon={weatherImage} action={"weather"} />
      </div>

      <div className="navBarBtns" hidden>
        <NavBarButton icon={itbaToolIcon4} />
      </div>
    </>
  );
}

export default HeaderButtons;

import React, { useRef, useEffect, useState } from "react";
import MapView from "@arcgis/core/views/MapView";
import WebMap from "@arcgis/core/WebMap";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import useMapStore from "../../../store/useMapStore";
import Track from "@arcgis/core/widgets/Track";
import Zoom from "@arcgis/core/widgets/Zoom";
import Sketch from "@arcgis/core/widgets/Sketch";
import getArea from "./calculations/getArea";
import getLength from "./calculations/getLength";
import PortalItem from "@arcgis/core/portal/PortalItem";
import Portal from "@arcgis/core/portal/Portal";
import Graphic from "@arcgis/core/Graphic";
import Point from "@arcgis/core/geometry/Point";
import PopupCard from "../popupCard";
import { useParams } from "react-router-dom";
import Query from "@arcgis/core/rest/support/Query";

function Map() {
  const mapDiv = useRef(null);
  let { hideHeader } = useParams();

  const trackAssign = useMapStore((state) => state.trackAssign);
  const zoomAssing = useMapStore((state) => state.zoomAssing);
  const layersAssign = useMapStore((state) => state.layersAssign);
  const viewAssign = useMapStore((state) => state.viewAssign);
  const sketchAssign = useMapStore((state) => state.sketchAssign);
  const [modalVisible, setModalVisible] = useState(false);
  const [showdata, setShowData] = useState<any>([]);

  const handleCard = () => {
    setModalVisible(false);
   }
  
  useEffect(() => {
    if (mapDiv.current) {
      const portalItem1 = new PortalItem();
      const portal = new Portal();
      portal.url = "https://gis.taqniaets.com/portal";

      portalItem1.portal = portal;
      portalItem1.id = "507df458685547e48bb1edd58e494f57";

      const webmap = new WebMap({
        portalItem: portalItem1,
      });

      const sketchLayer = new GraphicsLayer();
      sketchLayer.title = "طبقة الرسم";
      webmap.add(sketchLayer);

      const view = new MapView({
        container: mapDiv.current,
        map: webmap,
        zoom: 8,
      });
      view.ui.remove("zoom");
      view.popup.autoOpenEnabled = false;

      const graphicsLayer = new GraphicsLayer();
      webmap.add(graphicsLayer);

      const point = new Point();
      //Create a point

      const simpleMarkerSymbol = {
        type: "simple-marker",
        color: [226, 119, 40], // Orange
        size: 15,
        outline: {
          color: [255, 255, 255], // White
          width: 2,
        },
      };

      const pointGraphic = new Graphic({
        geometry: point,
        symbol: simpleMarkerSymbol,
      });

      viewAssign(view);
      const trackWidget = new Track({
        view: view,
        useHeadingEnabled: false,
        goToLocationEnabled: false
      });

   
          const Layer = new FeatureLayer({
            url: "https://gis.taqniaets.com/server/rest/services/ITBA_V3/FeatureServer/11",
          });

      trackWidget.on("track", async (event) =>  {
        var trackLocation = event.position.coords;
        var trackPoint = new Point({
          x: trackLocation.longitude,
          y: trackLocation.latitude
        });   
        
        const query = new Query();
        query.geometry = trackPoint;
        query.outFields = ["OBJECTID","NAME","Name2"]
        
        Layer.queryFeatures(query)
          .then((result) => {
            if (result.features.length > 0) {
              const attributes = result.features[0].attributes;
            if (attributes) {
              ShowPopup(attributes)
            }
          console.log("User's location is within the layer's boundary");
            }
            if (result.features.length <= 0) {
            console.log("User's location is outside the layer's boundary")
          }

        })
        .catch((error) => {
          console.error('Error performing the spatial query:', error);
        });
    });
      
      trackAssign(trackWidget);

      const zoomkWidget = new Zoom({
        view: view,
      });

      zoomAssing(zoomkWidget);

      view.layerViews.on("change", () => {
        layersAssign(view.allLayerViews);
      });

      const sketch = new Sketch();
      sketch.view = view;
      sketch.layer = sketchLayer;
      sketch.visibleElements.settingsMenu = false;
      sketch.visibleElements.undoRedoMenu = false;
      sketch.visible = false;

      sketchAssign(sketch);
      view.ui.remove("attribution");

      sketch.on("create", async (e) => {
        if (e.state === "complete") {
          console.log(e.graphic.attributes);
          switch (e.graphic.geometry.type) {
            case "polygon":
              console.log(getArea(e.graphic.geometry));
              break;
            case "polyline":
              console.log(getLength(e.graphic.geometry));
              break;
            default:
              console.log("No value found");
          }
        }
      });

      sketch.on("update", async (e) => {
        if (e.state === "complete") {
          e.graphics.forEach((g) => {
            switch (g.geometry.type) {
              case "polygon":
                console.log(getArea(g.geometry));
                break;
              case "polyline":
                console.log(getLength(g.geometry));
                break;
              default:
                console.log("No value found");
            }
          });
        }
      });

      view.on("click", (event: any) => {
        graphicsLayer.remove(pointGraphic);
        setModalVisible(false);
        const { x, y } = event;
        const { latitude, longitude } = event.mapPoint;
        point.longitude = longitude;
        point.latitude = latitude;
        view.hitTest({ x, y }).then((response: any) => {
          const [graphic] = response.results;
          if (graphic) {
            const attributes = graphic?.graphic?.attributes;
            if (attributes) {
              ShowPopup(attributes)
              graphicsLayer.add(pointGraphic);

            }
          }
        });
      });

      const ShowPopup = (attributes: any) => { 
        graphicsLayer.removeAll();
        setShowData(attributes);
        setModalVisible(true);
      }
      view.ui.add(sketch);

      view.on("click", async (e) => {
        console.log(
          "x & y & latitude & longitude = ",
          e.mapPoint.x,
          ",",
          e.mapPoint.y,
          ", latitude:",
          e.mapPoint.latitude,
          ",\n longitude:",
          e.mapPoint.longitude + ","
        );
        console.log("x & y =", e.x, ",", e.y);
      });
    }
  }, [trackAssign, zoomAssing, layersAssign, viewAssign, sketchAssign]);

  const mapStyle: React.CSSProperties = {
    padding: "0",
    margin: "0",
    height: hideHeader === "hideheader" ? "100vh" : "90vh",
    width: "100vw",
  };

  return (
    <>
      <div style={mapStyle} className="mapDiv" ref={mapDiv}></div>
      {modalVisible && <PopupCard key={showdata.OBJECTID} item={showdata} handleCard={handleCard} isCardVisible={modalVisible} />}
    </>
  );
}

export default Map;

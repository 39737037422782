import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import useMapStore from "../../store/useMapStore";
import { useState } from "react";
import useOffcanvasStore from "../../store/useOffcanvasStore";

function IconButton(props: { icon: IconDefinition; action?: string }) {
  const zoomObj = useMapStore((state) => state.zoom);
  const trackObj = useMapStore((state) => state.track);
  const setLayerShown = useOffcanvasStore(
    (state) => state.setLayerSelectionShown
  );
  const layerSelectionShown = useOffcanvasStore(
    (state) => state.layerSelectionShown
  );

  const [focusColor, setFocusColor] = useState<
    "var(--activeBtn)" | "var(--unactiveBtn)"
  >("var(--activeBtn)");

  const clickHandler = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    switch (props.action) {
      case "zoomIn":
        if (zoomObj.viewModel.canZoomIn) zoomObj.zoomIn();
        break;
      case "zoomOut":
        if (zoomObj.viewModel.canZoomOut) zoomObj.zoomOut();
        break;
      case "track":
        if (trackObj.tracking) {
          await trackObj.viewModel.stop();
          setFocusColor("var(--activeBtn)");
        } else {
          await trackObj.viewModel.start();
          setFocusColor("var(--unactiveBtn)");
        }

        trackObj.on("track-error", (e: any) => {
          setFocusColor("var(--activeBtn)");
        });

        break;
      case "layers":
        setLayerShown(!layerSelectionShown);
        break;
    }
  };

  const getButtonColor = () => {
    if (props.action === "layers") {
      if (layerSelectionShown) return "var(--unactiveBtn)";
      else return "var(--activeBtn)";
    } else return focusColor;
  };

  return (
    <button
      style={{
        ...buttonStyle,
        ...{ marginRight: layerSelectionShown ? "410px" : "10px" },
      }}
      className="my-3 row"
      onClick={clickHandler}
    >
      <div
        className="d-flex"
        style={{ ...boxStyle, backgroundColor: getButtonColor() }}
      >
        <FontAwesomeIcon color="#FFF" style={iconStyle} icon={props.icon} />
      </div>
    </button>
  );
}

export default IconButton;

const boxStyle: React.CSSProperties = {
  height: "45px",
  width: "45px",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "10px",
};

const iconStyle: React.CSSProperties = {
  height: "1.5rem",
};

const buttonStyle: React.CSSProperties = {
  border: "0",
  background: "none",
};

import { geodesicArea, planarArea } from "@arcgis/core/geometry/geometryEngine";

function GetArea(polygon: any) {
  const geoArea = geodesicArea(polygon, "square-kilometers");
  const planArea = planarArea(polygon, "square-kilometers");

  return {
    geoArea,
    planArea,
  };
}

export default GetArea;

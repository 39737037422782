import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useMapStore from '../../store/useMapStore';
import GetWeather, { IWeather } from '../home/map/getWeather';
import { useState } from 'react';

interface NavBarButtonProps {
  icon: string | React.ReactNode;
  action?: any;
}

function NavBarButton(props: NavBarButtonProps) {
  const view = useMapStore((state) => state.view);
  const sketch = useMapStore((state) => state.sketch);

  const [focus, setFocus] = useState<boolean>(false);
  const [handler, setHandler] = useState<any>(undefined);

  const clickHandler = (e: any) => {
    if (!view) return;
    switch (props.action) {
      case 'weather':
        if (focus) {
          setHandler(handler.remove());
          setFocus(false);
        } else {
          setHandler(
            view.on('click', async (e: any) => {
              const data: IWeather = await GetWeather(e.mapPoint);
              view.popup.open({
                location: e.mapPoint,
                title: data.title,
                content: data.content,
                includeDefaultActions: false,
              });
            })
          );
          setFocus(true);
        }
        break;
      case 'sketch':
        if (focus) {
          setFocus(false);
          sketch.visible = false;
        } else {
          setFocus(true);
          sketch.visible = true;
        }
        break;
    }
  };

  return (
    <div key={props.action} style={buttonContainerStyle}>
      <button style={buttonStyle} onClick={clickHandler}>
        {typeof props.icon === 'string' ? (
          <img src={props.icon} alt={`Icon for ${props.action}`} style={iconStyle} />
        ) : (
          <FontAwesomeIcon color="#FFFFFF" style={iconStyle} icon={props.icon as any} />
        )}
      </button>

      {focus && <div style={{ ...dashStyle, opacity: 1 }}></div>}
      {!focus && <div style={{ ...dashStyle }}></div>}
    </div>
  );
}

export default NavBarButton;

const buttonContainerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '3rem',
};

const iconStyle: React.CSSProperties = {
  height: '1.8rem',
  width: '1.8rem',
  display: 'block',
  margin: 'auto',
  textAlign: 'center',
};

const buttonStyle: React.CSSProperties = {
  border: '0',
  height: '100%',
  background: 'none',
  margin: '0 25px 0 25px',
};

const dashStyle: React.CSSProperties = {
  borderBottomWidth: '7px',
  borderBottom: 'inset',
  display: 'block',
  width: '70px',
  height: '7px',
  opacity: 0,
  borderBottomColor: 'var(--dark-peach)',
};

import "./sass/style.scss";
import Home from "./pages/home";
import { Route, Routes } from "react-router-dom";

function App() {
  return (
    <Routes>
      <Route path="/" Component={Home} />
      <Route path="/:hideHeader?" Component={Home} />
    </Routes>
  );
}

export default App;

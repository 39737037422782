import {
  planarLength,
  geodesicLength,
} from "@arcgis/core/geometry/geometryEngine";

function GetLength(line: any) {
  const geoLength = geodesicLength(line, "kilometers");
  const planLength = planarLength(line, "kilometers");

  return {
    geoLength,
    planLength,
  };
}

export default GetLength;

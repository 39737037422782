import { create } from "zustand";

interface ZoomState {
  layerSelectionShown: boolean;
  setLayerSelectionShown: (val: boolean) => void;
}

const useOffcanvasStore = create<ZoomState>()((set) => ({
  layerSelectionShown: false,
  setLayerSelectionShown: (val) => set(() => ({ layerSelectionShown: val })),
}));

export default useOffcanvasStore;
import Card from "react-bootstrap/Card";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


function GreenPopupCard({ name ,handleCard }:any,isCardVisible: any) {

  

  return isCardVisible ? (
    <div className="popupWarrper">
      <div className="secCaseIconPopup">
        <Card>
          <div className="row mt-3">
            <div className="col-2 my-auto">
              <img
                className="popup-imgStatus mx-auto mt-2 me-3"
                src="/img/popup-3.png"
                alt="icon title 1"
              />
            </div>
            <div className="col-8 my-auto">
              <div className="popupTitle">{name}</div>
            </div>

            <div className="col-2 my-auto text-secondary">
              <FontAwesomeIcon
                icon={faClose}
                onClick={handleCard}
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>

          <hr className="text-secondary" />

          <div className="popupContent">
            <div className="popupTitle"></div>
            <h6 className="popupSubTitle text-secondary my-3">
              يسمح بالتنزة واستخدام خيام المبيت الشخصية, <br />
              كما يسمح بالتخييم بعد الحصول على التصريح الفوري <br /> من الموقع
              الإلكتروني للهيئة وفق الشروط والأحكام
            </h6>
          </div>

          <Card.Body>
            <div className="rulesIconsSection">
              <div className="row">
                <div className="col">
                  <img
                    className="statuseIcons mx-auto"
                    src="/img/noHunt.png"
                    alt="icon title 1"
                  />
                  <div className="iconTitle">
                    <label className="text-secondary">يمنع</label>
                    <br />
                    <label className="text-secondary">الصيد</label>
                  </div>
                </div>

                <div className="col">
                  <img
                    className="statuseIcons mx-auto"
                    src="/img/noFireDesIcon.png"
                    alt="icon title 1"
                  />
                  <div className="iconTitle">
                    <label className="text-secondary"> يمنع اشعال النار</label>
                  </div>
                </div>

                <div className="col">
                  <img
                    className="statuseIcons mx-auto"
                    src="/img/noCutDes.png"
                    alt="icon title 1"
                  />
                  <div className="iconTitle">
                    <label className="text-secondary">يمنع </label>
                    <br />
                    <label className="text-secondary">الاحتطاب</label>
                  </div>
                </div>

                <div className="col">
                  <img
                    className="statuseIcons mx-auto"
                    src="/img/noCutTrees.png"
                    alt="icon title 1"
                  />
                  <div className="iconTitle">
                    <label className="text-secondary">يمنع قطع الاشجار</label>
                  </div>
                </div>
              </div>
            </div>

            <div className="anotherRulesIconsSection">
              <hr className="text-secondary" />

              <div className="row">
                <div className="col-2 my-auto ">
                  <img
                    className="mx-auto"
                    src="/img/roadIcon.png"
                    alt="icon title 1"
                  />
                </div>
                <div className="col-10 my-auto text-end p-0 text-secondary">
                  <label>
                    {" "}
                    يمنع دخول المركبات للفياض ويجب الالتزام بالمسارات المحددة{" "}
                  </label>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-2 my-auto">
                  <img
                    className="mx-auto"
                    src="/img/campIcon.png"
                    alt="icon title 1"
                  />
                </div>
                <div className="col-10 my-auto text-end p-0 text-secondary">
                  <label> يسمح التخييم بعد الحصول على التصريح الفوري </label>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-2 my-auto">
                  <img
                    className="mx-auto"
                    src="/img/firestationIcon.png"
                    alt="icon title 1"
                  />
                </div>
                <div className="col-10 my-auto text-end p-0 text-secondary">
                  <label>يسمح بإشعال النار على الموقد</label>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  ) : null;
}

export default GreenPopupCard;

import Point from "@arcgis/core/geometry/Point";
import ReactDOMServer from "react-dom/server";

interface IWeather {
  title: string;
  content: string;
}

async function GetWeather(mapPoint: Point): Promise<IWeather> {
  const response: Response = await fetch(
    "https://api.weatherapi.com/v1/current.json?key=237c043d69144fda82290122230903&q=" +
      mapPoint.latitude +
      "," +
      mapPoint.longitude +
      "&aqi=no"
  );

  if (!response.ok)
    return {
      title: "Filed to get Weather",
      content: "",
    };

  const data: any = await response.json();

  const currentDate = new Date();
  console.log(currentDate.getMonth());

  const getIcon = (code: number, isDay: boolean, iconUrl: string): string => {
    const path = window.location.href;
    switch (code) {
      case 1000:
        return isDay
          ? path + "/icons/weather/clearDay.png"
          : path + "/icons/weather/clearNight.png";
      case 1003:
        return isDay
          ? path + "/icons/weather/semiCloudDay.png"
          : path + "/icons/weather/semiCloudNight.png";
      case 1006:
        return path + "/icons/weather/cloudyDayNight.png";
      case 1009:
        return path + "/icons/weather/cloudyDayNight.png";
      case 1030:
        return path + "/icons/weather/mistDayNight.png";
      case 1195:
        return path + "/icons/weather/rainDayNight.png";
      case 1240:
        return isDay
          ? path + "/icons/weather/semiCloudDay.png"
          : path + "/icons/weather/semiCloudNight.png";
      case 1213:
        return path + "/icons/weather/snow.png";
      case 1225:
        return path + "/icons/weather/snow.png";
      default:
        return "https:" + iconUrl.replace("64x64", "128x128");
    }
  };

  const icon = getIcon(
    data["current"]["condition"]["code"],
    data["current"]["is_day"],
    data["current"]["condition"]["icon"]
  );
  console.log(icon);
  return {
    title: "",
    content: ReactDOMServer.renderToString(
      <>
        <div
          style={weatherHeaderStyle}
          id="weather-header"
          className="text-start px-3 text-white fs-6 fw-bold"
        >
          <p>
            {currentDate.toLocaleDateString("default", { weekday: "long" })}
          </p>

          <p>
            {currentDate.toLocaleString("default", { month: "long" })}{" "}
            {currentDate.getDate()}
          </p>
        </div>
        <div id="weather-body" className="text-center fw-bold">
          <div style={{ marginTop: "-15%" }}>
            <img style={{ width: "90px" }} src={icon} alt="weatherIcon" />
          </div>
          <div className="d-flex flex-column align-items-center justify-content-center my-1">
            <div className="weatherTitles text-secondary">
              {data["current"]["temp_c"]}&deg;C
            </div>
            <br />

            <div className="weatherTitles text-secondary">
              {data["current"]["condition"]["text"]}
            </div>

            <div className="row w-100 mt-5">
              <div className="col">
                <div className="weatherCardSubTitle">
                  سرعة الرياح:{data["current"]["wind_kph"]}kph
                </div>
              </div>
              <div className="col">
                <div className="weatherCardSubTitle">
                  اتجاه الرياح:{data["current"]["wind_dir"]}
                </div>
              </div>
            </div>
          </div>
          <br />
        </div>
      </>
    ),
  };
}

export type { IWeather };
export default GetWeather;

const weatherHeaderStyle: React.CSSProperties = {
  backgroundColor: "var(--brown-grey)",
  width: "100%",
  height: "57px",
};

import {
  faMinus,
  faPlus,
  faLocationArrow,
  faLayerGroup,
} from "@fortawesome/free-solid-svg-icons";
import IconButton from "../buttons/iconButton";
import LayerSelection from "./layerSelection";

function FloatingButtons() {
  return (
    <>
      <IconButton icon={faLocationArrow} action={"track"} />
      <IconButton icon={faPlus} action={"zoomIn"} />
      <IconButton icon={faMinus} action={"zoomOut"} />

      <LayerSelection />
    </>
  );
}

export default FloatingButtons;

import FloatingButtons from "../components/home/floatingButtons";
import HeaderNavBar from "../components/home/header/headerNavBar";
import Map from "../components/home/map/map";
import { useParams } from "react-router-dom";

function Home() {
  let { hideHeader } = useParams();

  return (
    <div className="" style={layoutStyle}>
      {hideHeader !== "hideheader" && (
        <div style={{ ...floatStyle, ...headerStyle }}>
          <HeaderNavBar />
        </div>
      )}
      <Map />

      <div style={floatStyle} className="position-absolute bottom-0">
        <FloatingButtons />
      </div>
    </div>
  );
}

export default Home;

const layoutStyle: React.CSSProperties = {
  height: "100vh",
  direction: "rtl",
};

const floatStyle: React.CSSProperties = {
  zIndex: 9,
};

const headerStyle: React.CSSProperties = {
  width: "100vw",
  height: "10vh",
};

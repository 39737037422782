import { create } from "zustand";

interface ZoomState {
  zoom?: any;
  zoomAssing: (func: any) => void;
  track?: any;
  trackAssign: (func: any) => void;
  layers?: any;
  layersAssign: (func: any) => void;
  view?: any;
  viewAssign: (func: any) => void;
  sketch?: any;
  sketchAssign: (func: any) => void;
}

const useZoomStore = create<ZoomState>()((set) => ({
  zoomAssing: async (func) => set(() => ({ zoom: func })),
  trackAssign: async (func) => set(() => ({ track: func })),
  layersAssign: async (func) => set(() => ({ layers: func })),
  viewAssign: async (func) => set(() => ({ view: func })),
  sketchAssign: async (func) => set(() => ({ sketch: func })),
}));

export default useZoomStore;

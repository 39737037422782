import GreenPopupCard from "./greenPopupCard";
import RedPopupCard from "./redPopupCared";
import YellowPopupCard from "./YelllowPopupCard";

function PopupCard({ item ,handleCard }:any,isCardVisible: any) {
  const renderComponent = () => {
    switch (item.OBJECTID) {
      case 41:
        return <RedPopupCard name={item.NAME} handleCard={handleCard} isCardVisible={isCardVisible}/>;
      case 48:
        return <RedPopupCard name={item.NAME} handleCard={handleCard} isCardVisible={isCardVisible}/>;
      case 39:
        return <RedPopupCard name={item.NAME} handleCard={handleCard} isCardVisible={isCardVisible}/>;
      case 40:
        return <RedPopupCard name={item.NAME} handleCard={handleCard} isCardVisible={isCardVisible}/>;
      case 43:
        return <YellowPopupCard name={item.NAME} handleCard={handleCard} isCardVisible={isCardVisible}/>;
      case 44:
        return <YellowPopupCard name={item.NAME} handleCard={handleCard} isCardVisible={isCardVisible}/>;
      case 46:
        return <GreenPopupCard name={item.NAME} handleCard={handleCard} isCardVisible={isCardVisible} />;
      case 42:
        return <GreenPopupCard name={item.NAME} handleCard={handleCard} isCardVisible={isCardVisible}/>;
      case 47:
        return <GreenPopupCard name={item.NAME} handleCard={handleCard} isCardVisible={isCardVisible}/>;
      case 38:
        return <GreenPopupCard name={item.NAME} handleCard={handleCard} isCardVisible={isCardVisible}/>;
      case 49:
        return <GreenPopupCard name={item.NAME} handleCard={handleCard} isCardVisible={isCardVisible}/>;
      case 45:
        return <GreenPopupCard name={item.NAME} handleCard={handleCard} isCardVisible={isCardVisible} />;
    }
  };
  return <div>{renderComponent()}</div>;
}

export default PopupCard;

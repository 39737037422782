import Card from "react-bootstrap/Card";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function RedPopupCard({ name ,handleCard }:any,isCardVisible: any) {
  
  return isCardVisible ? (
    <div className="popupWarrper">
      <div className="firstCaseIconPopup">
        <Card>
          <div className="row mt-3">
            <div className="col-2 my-auto">
              <img
                className="popup-imgStatus mx-auto mt-2 me-3"
                src="/img/popup-1.png"
                alt="icon title 1"
              />
            </div>
            <div className="col-8 my-auto">
              <div className="popupTitle">{name}</div>
            </div>

            <div className="col-2 my-auto text-secondary">
              <FontAwesomeIcon
                icon={faClose}
                onClick={handleCard}
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>

          <hr className="text-secondary" />

          <Card.Body>
            <div className="popupContent">
              <h6 className="popupSubTitle text-secondary mb-4">
                يمنع ممارسة انشطة التنزه والتخييم
              </h6>
            </div>

            <div className="rulesIconsSection">
              <div className="row mt-2">
                <div className="col">
                  <img
                    className="statuseIcons mx-auto"
                    src="/img/noEnteryIcon.png"
                    alt="icon title 1"
                  />
                  <div className="iconTitle">
                    <label className="text-secondary">يمنع دخول الاشخاص</label>
                  </div>
                </div>

                <div className="col">
                  <img
                    className="statuseIcons mx-auto"
                    src="/img/noHunt.png"
                    alt="icon title 1"
                  />

                  <div className="iconTitle">
                    <label className="text-secondary">يمنع</label>
                    <br />
                    <label className="text-secondary">الصيد</label>
                  </div>
                </div>

                <div className="col">
                  <img
                    className="statuseIcons mx-auto"
                    src="/img/noCamp.png"
                    alt="icon title 1"
                  />
                  <div className="iconTitle">
                    <label className="text-secondary">يمنع</label>
                    <br />
                    <label className="text-secondary">التخييم</label>
                  </div>
                </div>

                <div className="col">
                  <img
                    className="statuseIcons mx-auto"
                    src="/img/noCutTrees.png"
                    alt="icon title 1"
                  />
                  <div className="iconTitle">
                    <label className="text-secondary">يمنع قطع الاشجار</label>
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  ) : null;
}

export default RedPopupCard;
